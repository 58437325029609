<template>
    <v-ons-page>
        <div class="background"></div>
        <div ref="mapContainer" class="map-container"
             :class="{iPhoneMap:terminal === 'iPhone', AndroidMap:terminal === 'Android'}"
             @touchmove.passive.stop=""
             scroll.passive.stop="">
        </div>

        <div class="lower_part" :class="{iPhoneContent:terminal === 'iPhone', AndroidContent:terminal === 'Android'}">
            <div class="content">
                <div id="content">
                    <div class="button" @click="hutPush()">
                        <div class="hut">
                            <v-ons-icon id="hut" icon="md-home" size="40px"></v-ons-icon>
                        </div>
                    </div>
                </div>
                <div  id="content">
                    <div class="button" @click="facilityPush()">
                        <div class="facility">
                            <v-ons-icon id="male" icon="md-male-alt" size="40px"></v-ons-icon>
                            <v-ons-icon id="female" icon="md-female" size="40px"></v-ons-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
    import mapboxgl from "mapbox-gl";
    mapboxgl.accessToken = 'pk.eyJ1IjoidGVjaG5vZXllIiwiYSI6ImNsdDNsaWZoczF5OHEyanJvNXVsM2V3Z3EifQ.D4XO7xMtEWSBevi12Tr4Dg';
    import 'mapbox-gl/dist/mapbox-gl.css'
    // import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.js';
    // import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';

    export default {
        name: "MapBox",
        data() {
            return{
                device_id: 0,
                device_active: 0,
                terminal: this.$_Terminal(),

                focusFlg: true,
                markerLatLng: {
                    lat: 0,
                    lng: 0
                },

                hutMarkers: [],
                hutMarkersVisible: true,
                facilityMarkers: [],
                facilityMarkersVisible: true,

                FacilityIcons: {
                    1: {
                        url: '/img/map_icon/トイレ.png',
                        width: '30px',
                        height: '30px',
                    },
                    2: {
                        url: 'img/map_icon/ごみ箱.png',
                        width: '30px',
                        height: '30px',
                    },
                    3: {
                        url: 'img/map_icon/AED.png',
                        width: '30px',
                        height: '30px',
                    },
                    4: {
                        url: 'img/map_icon/監視塔.png',
                        width: '40px',
                        height: '40px',
                    },
                    5: {
                        url: 'img/map_icon/救護.png',
                        width: '40px',
                        height: '40px',
                    },
                    6: {
                        url: 'img/map_icon/本部.png',
                        width: '40px',
                        height: '40px',
                    },
                    7: {
                        url: 'img/map_icon/通行止め.png',
                        width: '40px',
                        height: '40px',
                    },
                    8: {
                        url: 'img/map_icon/駐車場.png',
                        width: '40px',
                        height: '40px',
                    },
                    9: {
                        url: 'img/map_icon/駐輪場.png',
                        width: '40px',
                        height: '40px',
                    },
                    10: {
                        url: 'img/map_icon/喫煙所.png',
                        width: '40px',
                        height: '40px',
                    },
                    11: {
                        url: 'img/map_icon/地車休憩所.png',
                        width: '40px',
                        height: '40px',
                    },
                },
            }
        },
        mounted() {
            let self = this

            //広域変数からデータを取得
            this.townData = this.$_TownData();

            const map = new mapboxgl.Map({
                container: this.$refs.mapContainer,
                style: "mapbox://styles/mapbox/streets-v12",
                center: [parseFloat(this.$_FestivalData().festival_longitude), parseFloat(this.$_FestivalData().festival_latitude)],
                zoom: 15,
                attributionControl: false,
                pitchWithRotate: false, // 回転によるピッチ変更を無効化
                rotate: false // 地図の回転を無効化
            });
            this.map = map
            // disable map rotation using right click + drag
            map.dragRotate.disable();
            // disable map rotation using touch rotation gesture
            map.touchZoomRotate.disableRotation()

            //日本語対応
            const MapboxLanguage = require('@mapbox/mapbox-gl-language')
            map.addControl(
                new MapboxLanguage({
                    defaultLanguage: 'ja'
                }),
            );
            // GeolocateControl を作成
            const geolocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true
            });
            // マップに GeolocateControl を追加
            map.addControl(geolocate, 'bottom-right');
            // GeolocateControlを手動でトリガーして現在位置を表示
            map.on('load', () => {
                geolocate.trigger();
            });
            // ユーザーの位置の追跡が開始されたときに発生します。
            geolocate.on('trackuserlocationstart', function() {
                self.focusFlg = false;
            });
            // ユーザーの位置の追跡が停止されたときに発生します。
            geolocate.on('trackuserlocationend', function(e) {
                if(e.target._watchState == "OFF"){
                    self.focusFlg = true;
                }
            });

            // // ルート検索の設定
            // const directions = new MapboxDirections({
            //     accessToken: mapboxgl.accessToken,
            //     language: 'ja-JP',
            //     interactive: true,
            //     profile: 'mapbox/walking',
            //     controls: {
            //         inputs: false,
            //         profileSwitcher: false,
            //         instructions: false,
            //         waypoints: false,
            //     },
            // });
            // this.directions = directions
            // // ルート検索コントロールを地図に追加
            // map.addControl(directions, 'top-left');

            //現在位置セット
            // Geolocation APIを使用して現在地を取得
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords

                // const markerElement = document.createElement('div');
                // markerElement.className = 'hut-location';
                // markerElement.style.backgroundImage = `url(/img/current_location.png)`;
                // markerElement.style.backgroundSize = 'cover';
                // markerElement.style.width = '30px';
                // markerElement.style.height = '45px';
                // // Map上に現在地を表示するMarkerを作成
                // this.marker = new mapboxgl.Marker(markerElement, {
                //     draggable: false,
                // })
                //     .setLngLat([longitude, latitude])
                //     .addTo(map)
                //マップの中心を現在地にセット
                this.focus(longitude, latitude)
                // //ルート検索の出発点を更新
                // directions.setOrigin([longitude, latitude]);
            })

            // リアルタイムで位置情報を更新
            navigator.geolocation.watchPosition(position => {
                const { latitude, longitude } = position.coords
                // Markerの位置を更新
                //this.marker.setLngLat([longitude, latitude])
                this.markerLatLng.lat = latitude
                this.markerLatLng.lng = longitude
            })
            setInterval(() => {
                if(this.focusFlg == false){
                    this.focus(this.markerLatLng.lng, this.markerLatLng.lat)
                }
            }, 600000);

            //小屋情報セット
            const hutMarkerElement = document.createElement('div');
            hutMarkerElement.className = 'hut-location';
            hutMarkerElement.style.backgroundImage = `url(${this.$_TownData().hut_img})`;
            hutMarkerElement.style.backgroundSize = 'cover';
            hutMarkerElement.style.width = '50px';
            hutMarkerElement.style.height = '50px';
            const hutMarker = new mapboxgl.Marker(hutMarkerElement)
                .setLngLat([this.$_TownData().storehouse_longitude, this.$_TownData().storehouse_latitude])
            this.hutMarkers.push(hutMarker)

            let apiVal = {
                festival_id: this.$_festivalIDS()
            }
            //施設情報セット
            let getData = this.$_mixAPI_testMapIcon(apiVal)
            getData.then(function(val){
                val.forEach((value) => {
                    const facilityMarkerElement = document.createElement('div');
                    facilityMarkerElement.className = 'facility-location';
                    facilityMarkerElement.style.backgroundImage = `url(${self.FacilityIcons[value.category_flg].url})`;
                    facilityMarkerElement.style.backgroundSize = 'cover';
                    facilityMarkerElement.style.width = self.FacilityIcons[value.category_flg].width;
                    facilityMarkerElement.style.height = self.FacilityIcons[value.category_flg].height;

                    const facilityMarker = new mapboxgl.Marker(facilityMarkerElement)
                        .setLngLat([value.location_longitude, value.location_latitude]);
                    self.facilityMarkers.push(facilityMarker)
                })
            })

            //GPS情報をGET
            let api = {
                town_id: this.$_TownID()
            }
            let getResultAPI = this.$_mixAPI_townGPS(api)
            getResultAPI.then(function(val){
                self.device_id = val.device_id
                self.device_active = val.device_active
            })
            //GPS 使用確認
            this.intervalID = setInterval(() => {
                let apiVal = {
                    town_id: this.$_TownID()
                }
                let getResultAPI = this.$_mixAPI_townGPS(apiVal)
                getResultAPI.then(function(val){
                    self.device_id = val.device_id
                    self.device_active = val.device_active
                })
            },300000)
            // 既存のマーカーを保存するための変数
            let marker;
            // GPS位置情報取得
            this.intervalID = setInterval(() => {
                if(this.device_active == 1){
                    let apiVal = {
                        device_num: this.device_id
                    }
                    let getResultAPI = this.$_mixAPI_GPS_getLatLng(apiVal)
                    getResultAPI.then(function(val){
                        let parVal = JSON.parse(val.body)
                        if (parVal.length !== 0) {
                            // 既存のマーカーがあれば位置を更新
                            if (marker) {
                                marker.setLngLat([parVal[0].long, parVal[0].lat]);
                                // マップの中心を現在地にセット
                                if(self.focusFlg == true){
                                    self.focus(parVal[0].long, parVal[0].lat)
                                }
                            } else {
                                const jigurumaMarkerElement = document.createElement('div');
                                jigurumaMarkerElement.className = 'jiguruma-location';
                                jigurumaMarkerElement.style.backgroundImage = `url(${self.townData.town_icon})`;
                                jigurumaMarkerElement.style.backgroundSize = '100% 100%';
                                jigurumaMarkerElement.style.backgroundPosition = 'center';
                                jigurumaMarkerElement.style.width = '50px';
                                jigurumaMarkerElement.style.height = '50px';

                                // マーカーを地図に追加
                                marker = new mapboxgl.Marker(jigurumaMarkerElement)
                                    .setLngLat([parVal[0].long, parVal[0].lat])
                                    .addTo(self.map);
                                // マップの中心を現在地にセット
                                if(self.focusFlg == true){
                                    self.focus(parVal[0].long, parVal[0].lat)
                                }
                            }
                        } else {
                            if (marker) {
                                marker.remove();
                            }
                        }
                    }).catch((err)=>{   //通信エラー時
                        console.log(err);
                    })
                } else {
                    if (marker) {
                        marker.remove();
                    }
                }
            },5000)
        },
        methods:{
            focus(lng, lat){
                this.map.setCenter([lng, lat]);
            },
            // 小屋の表示、非表示を切り替えるメソッド
            hutPush() {
                this.hutMarkers.forEach((marker) => {
                    if (this.hutMarkersVisible) {
                        marker.addTo(this.map);
                    } else {
                        marker.remove();
                    }
                });
                this.hutMarkersVisible = !this.hutMarkersVisible;
            },
            // 施設の表示、非表示を切り替えるメソッド
            facilityPush() {
                this.facilityMarkers.forEach((marker) => {
                    if (this.facilityMarkersVisible) {
                        marker.addTo(this.map);
                    } else {
                        marker.remove();
                    }
                });
                this.facilityMarkersVisible = !this.facilityMarkersVisible;
            },
        },
    }
</script>

<style scoped>
    .map-container {
        width: 100%;
        height: calc(100% - 70px);
        margin: 0;
    }
    .iPhoneMap{
        width: 100%;
        height: calc(100% - 70px);
        margin: 0;
    }
    .iPhoneContent{
        background-color: #313131;
        width: 100%;
        height: 70px;
        margin: 0;
    }
    .AndroidMap{
        width: 100%;
        height: calc(100% - 85px);
        margin: 0;
    }
    .AndroidContent{
        background-color: #313131;
        width: 100%;
        height: 85px;
        margin: 0;
    }
    .lower_part{
        background-color: #2b2b2b;
        width: 100%;
        margin: 0;
        bottom: 0;
        position: absolute;
    }
    .hut {
        padding: 1%;
    }
    .facility {
        padding: 1%;
    }
    #hut{
        color: white;
    }
    #male{
        color: white;
    }
    #female{
        color: white;
        margin-left: 5%;
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    #content{
        flex-grow: 1;
        width: 50%;
        height: 50px;
        margin: 10px 0 10px 0;
    }
    .button {
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 90%;
        background: linear-gradient(#16160e, #383c3c 50%, #16160e);
        box-shadow: 0 3px 0 #000;
        border-radius: 2rem;
        cursor: pointer;
        overflow: hidden;
        transition: transform 0.2s ease-in-out;
    }
    .button span {
        color: #fff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    }
    .button:hover {
        background-color: #404040;
        box-shadow: 0 3px 0 #000;
    }
    .button:active {
        box-shadow: 0 1px 0 #000;
        transform: translateY(2px);
    }
</style>