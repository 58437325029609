// *******************************
// APIMixinファイル
// ******************************
// import Vue from 'vue';
import Http from '../common/axios'
//
const headers = {
    "Content-Type": "application/json"
}

var instance = null;

export const mixAPI = {
    data () {
        return {
            instance: instance
        }
    },
    created() {
        let jConfig = this.mixCF_config;
        let api_url = jConfig[0].api_url; //初configから取得
        this.instance = new Http(api_url, headers);
    },
    methods: {
        async $_mixAPI_gethttpbin() {
            // GET取得サンプル
            const res = await this.instance.get('/get')
            return res.body
        },
        async $_mixAPI_posthttpbin(data) {
            //POST取得サンプル
            const res = await this.instance.post('/post', data)
            return res.body
        },
        async $_mixAPI_getDescriptionFlg(data) {
            const res = await this.instance.post('/getDescriptionFlg', data)
            return res.body
        },
        async $_mixAPI_updateDescriptionFlg(data) {
            const res = await this.instance.post('/updateDescriptionFlg', data)
            return res.body
        },
        async $_mixAPI_getFestivals(data) {
            const res = await this.instance.post('/getFestivals', data)
            return res.body
        },
        async $_mixAPI_getFestivalData(data) {
            const res = await this.instance.post('/getFestivalData' ,data)
            return res.body
        },
        async $_mixAPI_postTownList(data) {
            const res = await this.instance.post('/getTowns', data)
            return res.body
        },
        async $_mixAPI_postTownData(data) {
            const res = await this.instance.post('/getTownData', data)
            return res.body
        },
        async $_mixAPI_postCourseData(data) {
            const res = await this.instance.post('/getCourseData', data)
            return res.body
        },
        async $_mixAPI_getAllSponsor(){
            const res = await this.instance.get('/getAllSponsor')
            return res.body
        },
        async $_mixAPI_postSponsorData(data) {
            const res = await this.instance.post('/getSponsorData', data)
            return res.body
        },
        async $_mixAPI_postMainSponsorData(data) {
            const res = await this.instance.post('/getMainSponsorData', data)
            return res.body
        },
        async $_mixAPI_postNewsData(data) {
            const res = await this.instance.post('/getNewsData', data)
            return res.body
        },
        async $_mixAPI_postdeleteNews(data) {
            const res = await this.instance.post('/deleteNews', data)
            return res.body
        },
        async $_mixAPI_postUpdateNews(data) {
            const res = await this.instance.post('/updateNews', data)
            return res.body
        },
        async $_mixAPI_postregistNews(data) {
            const res = await this.instance.post('/registNews', data)
            return res.body
        },
        async $_mixAPI_getAllNews(data){
            const res = await this.instance.post('/getAllNews', data)
            return res.body
        },
        async $_mixAPI_getDanjiriNews(Params) {
            const res = await this.instance.post('/getDanjiriNews', Params)
            return res.body
        },
        async $_mixAPI_registDanjiriNews(data) {
            const res = await this.instance.post('/registDanjiriNews', data)
            return res.body
        },
        async $_mixAPI_checkNewsPassword(data) {
            const res = await this.instance.post('/checkNewsPassword', data)
            return res.body
        },
        async $_mixAPI_checkAdminPassword(data) {
            const res = await this.instance.post('/checkAdminPassword', data)
            return res.body
        },
        async $_mixAPI_postPasswordAPI(data) {
            const res = await this.instance.post('/checkTownPassword', data)
            return res.body
        },
        async $_mixAPI_postRegistFavorite(data) {
            const res = await this.instance.post('/registFavorite', data)
            return res.body
        },
        async $_mixAPI_postGetFavorite(data) {
            const res = await this.instance.post('/getFavorites', data)
            return res.body
        },
        async $_mixAPI_registDeviceToken(Params) {
            const res = await this.instance.post('/registDeviceToken', Params)
            return res.body
        },
        async $_mixAPI_pushNotification(Params) {
            const res = await this.instance.post('/pushNotification', Params)
            return res.body
        },
        async $_mixAPI_getTemporarytoilet(params) {
            const res = await this.instance.post('/getTemporarytoilet', params)
            return res.body
        },
        async $_mixAPI_logMonitoring(params){
            const res = await this.instance.post('/logMonitoring', params)
            return res.body
        },
        async $_mixAPI_logAdd(params){
            const res = await this.instance.post('/logAdd', params)
            return res.body
        },
        async $_mixAPI_getNotificationNews(params){
            const res = await this.instance.post('/getNotificationNews', params)
            return res.body
        },
        async $_mixAPI_getFavoriteTownNews(params){
            const res = await this.instance.post('/getFavoriteTownNews', params)
            return res.body
        },
        async $_mixAPI_getCategory(){
            const res = await this.instance.get('getCategorys')
            return res.body
        },
        async $_mixAPI_getSponsorList(params){
            const res = await this.instance.post('getSponsorList', params)
            return res.body
        },
        async $_mixAPI_getSponsorDetail(params){
            const res = await this.instance.post('getSponsorDetail', params)
            return res.body
        },
        async $_mixAPI_versionCheck(){
            const res = await this.instance.get('/versionCheck')
            return res.body
        },
        async $_mixAPI_gpsActive(params){
            const res = await this.instance.post('gpsActive', params)
            return res.body
        },
        async $_mixAPI_testMapIcon(params){
            const res = await this.instance.post('testMapIcon', params)
            return res.body
        },
        async $_mixAPI_getNenbanNews(params){
            const res = await this.instance.post('getNenbanNews', params)
            return res.body
        },
        async $_mixAPI_getFesNotification(params){
            const res = await this.instance.post('getFesNotification', params)
            return res.body
        },
        async $_mixAPI_nenbanLogMonitoring(params){
            const res = await this.instance.post('nenbanLogMonitoring', params)
            return res.body
        },
        async $_mixAPI_UpTo3Notifications(){
            const res = await this.instance.get('UpTo3Notifications')
            return res.body
        },
        async $_mixAPI_Notifications(){
            const res = await this.instance.get('Notifications')
            return res.body
        },
        async $_mixAPI_Inquiry(params){
            const res = await this.instance.post('inquiry', params)
            return res.body
        },
        async $_mixAPI_AllTownGPS(params){
            const res = await this.instance.post('AllTownGPS', params)
            return res.body
        },
        async $_mixAPI_townGPS(params){
            const res = await this.instance.post('townGPS', params)
            return res.body
        },
        //スケジュール
        async $_mixAPI_getSchedule(params){
            const res = await this.instance.post('/getSchedule', params)
            return res.body
        },
        async $_mixAPI_getFestivalSchedule(params){
            const res = await this.instance.post('/getFestivalSchedule', params)
            return res.body
        },
        async $_mixAPI_getCalendarEvents(){
            const res = await this.instance.get('/getCalendarEvents')
            return res.body
        },
        async $_mixAPI_getScheduleDetail(params){
            const res = await this.instance.post('getScheduleDetail', params)
            return res.body
        },
        async $_mixAPI_getClickCalendarEvents(params){
            const res = await this.instance.post('getClickCalendarEvents', params)
            return res.body
        },
        async $_mixAPI_registInquiry(params){
            const res = await this.instance.post('registInquiry', params)
            return res.body
        },
        //販売管理
        async $_mixAPI_registPurchasers(params){
            const res = await this.instance.post('registPurchasers', params)
            return res.body
        },
        async $_mixAPI_registProductDetails(params){
            const res = await this.instance.post('registProductDetails', params)
            return res.body
        },
        async $_mixAPI_AddressSearch(params){
            const res = await this.instance.post('addressSearch', params)
            return res.body
        },
        //布団太鼓
        async $_mixAPI_getFutonInfo(){
            const res = await this.instance.get('/getFutonInfo')
            return res.body
        },
        async $_mixAPI_getFutonGoods(){
            const res = await this.instance.get('/getFutonGoods')
            return res.body
        },
    }

}
